var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-md" },
    [
      _c(
        "a-table",
        {
          attrs: {
            pagination: false,
            columns: _vm.columns,
            "data-source": _vm.codeList,
            "row-key": function(record) {
              return record.id
            },
            "row-selection": _vm.rowSelection,
            locale: {
              emptyText: _vm.$t("No data")
            }
          },
          scopedSlots: _vm._u([
            {
              key: "status",
              fn: function(status) {
                return [
                  status === "success"
                    ? _c("a-badge", {
                        attrs: { status: "success", text: _vm.$t("Successful") }
                      })
                    : _vm._e(),
                  status === "ready"
                    ? _c("a-badge", {
                        attrs: { status: "processing", text: _vm.$t("Ready") }
                      })
                    : _vm._e(),
                  status === "error"
                    ? _c("a-badge", {
                        attrs: { status: "error", text: _vm.$t("Error") }
                      })
                    : _vm._e(),
                  status === "processing"
                    ? _c(
                        "div",
                        { staticClass: "preview-status-process" },
                        [
                          _c("a-icon", {
                            staticStyle: { color: "#3498db" },
                            attrs: { type: "loading" }
                          }),
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.$t("Processing")))
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "codeName",
              fn: function(codeName) {
                return [
                  _c("span", [_vm._v(_vm._s(_vm._f("truncate")(codeName, 40)))])
                ]
              }
            },
            {
              key: "codeType",
              fn: function(codeType) {
                return [
                  _c("a-tag", { attrs: { color: _vm.colorType[codeType] } }, [
                    _vm._v(" " + _vm._s(_vm.$t(codeType)) + " ")
                  ])
                ]
              }
            },
            {
              key: "message",
              fn: function(text, record) {
                return [
                  record.status === "error"
                    ? _c("a-alert", {
                        attrs: {
                          message: _vm.$t(text),
                          type: "error",
                          "show-icon": ""
                        }
                      })
                    : _vm._e()
                ]
              }
            },
            {
              key: "action",
              fn: function(text, record) {
                return _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.remove(record)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("Remove")))]
                )
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "customTitle" }, slot: "customTitle" }, [
            _vm._v(_vm._s(_vm.$t("File name")))
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "content-center my-lg" },
        [
          _c(
            "a-button",
            {
              staticClass: "mr-md",
              attrs: {
                icon: "save",
                disabled: !_vm.validateButton,
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.handleSaveDraft($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Save as draft")) + " ")]
          ),
          _vm.validateButton
            ? _c(
                "a-popconfirm",
                {
                  attrs: {
                    placement: "top",
                    title: _vm.$t(
                      "code.importCode.Are you sure to generate code?"
                    ),
                    "ok-text": _vm.$t("Yes"),
                    "cancel-text": _vm.$t("No")
                  },
                  on: { confirm: _vm.handlePublish }
                },
                [
                  _c(
                    "a-button",
                    { class: { blink: true }, attrs: { loading: _vm.loading } },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "15px",
                          height: "15px",
                          "margin-right": "5px"
                        },
                        attrs: { src: require("@/assets/logo.png") }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("Generate")) + " ")
                    ]
                  )
                ],
                1
              )
            : _c(
                "a-button",
                { class: { blink: true }, attrs: { disabled: "" } },
                [
                  _c("img", {
                    staticStyle: {
                      width: "15px",
                      height: "15px",
                      "margin-right": "5px"
                    },
                    attrs: { src: require("@/assets/logo.png") }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("Generate")) + " ")
                ]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }