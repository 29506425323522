//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { generateCodeType } from '@/utils/util';

export default {
  props: {
    codeList: {
      type: Array,
      required: true,
      default: () => []
    },
    meta: {
      type: Object,
      default: () => ({})
    },
    category: {
      type: Object
    }
  },
  data() {
    return {
      selectedRowKeys: [],
      loading: false
    };
  },
  computed: {
    columns() {
      return Object.freeze([
        {
          dataIndex: 'name',
          key: 'name',
          slots: { title: 'customTitle' },
          scopedSlots: { customRender: 'name' },
          width: '15%'
        },
        {
          title: this.$t('Code Name'),
          key: 'codeName',
          dataIndex: 'codeName',
          scopedSlots: { customRender: 'codeName' },
          width: '20%'
        },
        {
          title: this.$t('Code Type'),
          key: 'codeType',
          dataIndex: 'codeType',
          scopedSlots: { customRender: 'codeType' },
          width: '15%'
        },
        {
          title: this.$t('Status'),
          key: 'status',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: '15%'
        },
        {
          title: this.$t('Import File Message'),
          key: 'message',
          dataIndex: 'message',
          scopedSlots: { customRender: 'message' }
          // width: '30%',
        },
        {
          title: this.$t('Actions'),
          dataIndex: '',
          key: 'x',
          scopedSlots: { customRender: 'action' },
          width: '10%'
        }
      ]);
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: selectedRowKeys => {
          this.selectedRowKeys = selectedRowKeys;
        },
        getCheckboxProps: record => ({
          props: {
            id: record.id,
            name: record.name,
            disabled: ['success', 'processing', 'error'].includes(record.status)
          }
        })
      };
    },
    projectId() {
      return this.$route.params.projectId;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    colorType() {
      return {
        spot_code: 'cyan',
        navi_code: 'blue',
        mix_code: 'purple'
      };
    },
    validateButton() {
      if (this.selectedRowKeys.length) return true;
      return false;
    }
  },
  methods: {
    refreshSelected() {
      this.selectedRowKeys = this.codeList
        .filter(item => !['success', 'processing', 'error', 'ready'].includes(item.status))
        .map(code => code.id);
    },
    handleSaveDraft() {
      this.submit('DRAFT');
    },
    handlePublish() {
      this.submit('GENERATE');
    },
    async submit(status = 'DRAFT') {
      const listFile = this.codeList.filter(file => this.selectedRowKeys.includes(file.id));
      try {
        this.loading = true;
        const listGenerate = listFile.map(file => {
          const input = {
            status,
            title: file.name.trim(),
            sym_size: this.meta.size,
            sym_ec_level: this.meta.ecLevel,
            code_type: generateCodeType(file.codes),
            codes: file.codes.map(code => ({ ...code, navi_type: `${code.navi_type}` })),
            category_id: this.category.id
          };
          return this.$s.api.page.createCodes(this.pageId, input);
        });

        const respond = await Promise.allSettled(listGenerate);
        const filesSuccess = respond.filter(file => file.status === 'fulfilled');
        const filesError = respond.filter(file => file.status === 'rejected');
        for (let index = 0; index < listFile.length; index++) {
          respond[index].name = listFile[index].name;
        }
        await this.$emit('updateCodeList', {
          filesSuccess: filesSuccess.map(item => ({
            name: item.value.title,
            status: 'success'
          })),
          filesError: filesError.map(item => {
            return {
              name: item.name,
              message: this.$t(item.reason.message.trim()),
              status: 'error'
            };
          })
        });
        this.refreshSelected();

        if (filesError.length > 0) {
          this.notify('error');
        } else {
          this.notify('success', status);
          this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes/search`);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    notify(type = 'success', status) {
      const notify = {
        DRAFT: this.$t('SaveDraftSuccess'),
        GENERATE: this.$t('SaveGenerateSuccess')
      };
      const config = {
        success: {
          type: 'success',
          message: notify[status]
        },
        error: {
          type: 'error',
          message: this.$t('Generate error')
        }
      };
      this.$notification[type](config[type]);
    },
    remove(data) {
      this.$emit('remove', data);
    }
  }
};
